import { createSlice } from "@reduxjs/toolkit";

const searchFilterData = {
    filters: null,
    Requestsearchfilters: null,
    filtersSessionTimes: null,
    isFilterRemoved: false,
    isRequestFilterRemoved: false,
    loadingApi: false
};

const searchFilterSlice = createSlice({
    // Slice for searchFilter
    name: "search-filter",
    initialState: searchFilterData,
    reducers: {
        // set search filters
        setSearchFilters(state, action) {
            state.filters = action.payload;
            // localStorage.setItem(
            //     "searchfilter",
            //     JSON.stringify(action.payload)
            // );
        },

        // set request search filters
        setRequestSearchFilters(state, action) {
            state.Requestsearchfilters = action.payload;
            // localStorage.setItem(
            //     "Requestsearchfilter",
            //     JSON.stringify(action.payload)
            // );
        },

        setFiltersSessionTimes(state, action) {
            state.filtersSessionTimes = action.payload
        },

        removeFilters(state) {
            state.isFilterRemoved = true;
            state.filters = null;
            localStorage.setItem(
                "searchfilter",
                null
            );

            state.filtersSessionTimes = null;
            localStorage.setItem(
                "sessionTimes",
                null
            );
        },

        removeRequestFilters(state) {
            state.isRequestFilterRemoved = true;
            state.Requestsearchfilters = null;
            localStorage.setItem(
                "Requestsearchfilter",
                null
            );

            // state.filtersSessionTimes = null;
            localStorage.setItem(
                "RequestsessionTimes",
                null
            );
        },

        setLoadingApi(state, action) {
            state.loadingApi = action.payload;
        }
    },
});

export const searchFilterActions = searchFilterSlice.actions;

export default searchFilterSlice;
