import React from 'react'

const JobFilterTabs = ({ postType, onChangeTab }) => {

    const onTabChange = (type) => {
        onChangeTab(type);
    }

    return (
        <div className="ct_job_tab_btns">
            <button className={`save_changes_btn py-2 ${postType === "Job Wanted" ? "active" : ""}`} onClick={() => onTabChange('Job Wanted')}>Job Wanted</button>
            <button className={`save_changes_btn py-2 ${postType === "Job Offered" ? "active" : ""}`} onClick={() => onTabChange('Job Offered')}>Job Offered</button>

        </div>
    )
}

export default JobFilterTabs