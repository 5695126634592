export const Step1 = (props) => {
    return (
        <>
            <div class="overview-msger ct_box_shadow" >
                <div><h3 style={{ marginBottom: "30px" }}>Pre Registration Video</h3></div>
                <div class="embed-responsive embed-responsive-16by9">
                    <video src={`${process.env.REACT_APP_URL}/public/storage/videos/sample_video_360.mp4`} width="600" height="300" controls="controls" autoplay="true" />
                </div>


                <button
                    type="button"
                    class="save_changes_btn float-right w-auto mt-4 ms-auto px-4"
                    onClick={() => props.onClick(true)}
                >
                    Next
                </button>
            </div>
        </>
    )

}