import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Spinner } from "react-bootstrap";
import GoogleMapComponent from "../../components/GoogleMap/GoogleMapComponent";
import { signupUser } from "../../api_services/api_calls/authApi";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const validationSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    middleName: yup.string(),
    lastName: yup.string().required("Last name is required"),
    cooperationName: yup.string(),
    mailingAddress: yup.string().required("Mailing address is required"),
    zipCode: yup
        .string()
        .matches(/^[a-zA-Z0-9\s\-]{3,10}$/, "Enter a valid zip/postal code")
        .required("Zip/postal code is required"),
    yearOfBirth: yup.string().required("This field is required"),
    cellPhoneNumber: yup
        .string()
        .matches(
            /^\+?[1-9]\d{1,14}$/,
            "Enter a valid phone number (e.g., +123456789)"
        )
        .required("Cell phone number is required"),
    officeHomePhoneNumber: yup
        .string()
        .matches(
            /^\+?[1-9]\d{1,14}$/,
            "Enter a valid phone number (e.g., +123456789)"
        )
        .nullable(),
    marineIndustry: yup.string().required("This field is required"),
    receiveCall: yup.string().required("This field is required"),
    primarySocialMedia: yup.string().required("This field is required"),
    secondarySocialMedia: yup.string().required("This field is required"),
    primarySearchEngine: yup.string().required("This field is required"),
    secondarySearchEngine: yup.string().required("This field is required"),
    additionalInformation: yup.string(),
});

// Calculate the cutoff date for 10 years ago
const getMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 10);
    return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
};

const SingupForm = () => {
    const [zipCode, setZipCode] = useState(""); // State for zip code
    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState("");
    const [lat_lng, setLatLng] = useState("");
    const [address, setAddress] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    // Synchronize zipCode state with react-hook-form
    useEffect(() => {
        setValue("zipCode", zipCode); // Update the form value
    }, [zipCode, setValue]);

    const onSubmit = (data) => {
        setSubmitting(true);
        const formData = {
            ...data,
            zipCode: zipCode,
            countryId: countryId,
            stateId: stateId,
            lat_lng: lat_lng,
            address: address,
        };

        signupUser(formData)
            .then((respose) => {
                console.log("respose", respose);
                window.location.replace("/dashboard")
            })
            .catch((error) => { })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const changeAddress = (childData) => {
        console.log("childData - postal code", childData);

        if (
            childData.postal_code === undefined ||
            childData.postal_code === "Postal code not found"
        ) {
            alert("No Postal Code available");
            return false;
        }
        setAddress(childData.address);
        setZipCode(childData.postal_code);
        setLatLng(childData.lat_lng);
        setStateId(childData.state_id);
        setCountryId(childData.country_id);
    };

    let yearsArray = [];
    for (let i = new Date().getFullYear() - 10; i >= 1950 - 10; i--) {
        yearsArray.push(i);
    }

    return (
        <div class="overview-msger ct_box_shadow" style={{ maxWidth: "700px" }}>
            <div
                className="pb-2 mb-4 mt-4"
                style={{ borderBottom: "2px solid rgb(140 144 147)" }}
            >
                <h3 className="text-center text-secondary">Contact Information Form</h3>
            </div>
            <p className="text-center text-secondary">
                To better serve you, we have a one-time questionnaire to gather some
                important information. Once completed, you'll have full access to the
                website. Please fill in the defails below.
            </p>
            <form
                onSubmit={handleSubmit(onSubmit)}
            // style={{ maxWidth: "500px", margin: "0 auto" }}
            >
                <div
                    className="pb-2 mb-4 mt-4"
                    style={{ borderBottom: "2px solid rgb(140 144 147)" }}
                >
                    <h6 className="text-secondary">Personal Information</h6>
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        First Name <span className="text-danger">*</span>
                    </label>
                    <input className="form-control" {...register("firstName")} />
                    <p className="text-danger">{errors.firstName?.message}</p>
                </div>
                <div className="form-group">
                    <label className="text-secondary">Middle Name</label>
                    <input className="form-control" {...register("middleName")} />
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        Last Name <span className="text-danger">*</span>
                    </label>
                    <input className="form-control" {...register("lastName")} />
                    <p className="text-danger">{errors.lastName?.message}</p>
                </div>
                <div className="form-group">
                    <label className="text-secondary">Cooperation Name</label>
                    <input className="form-control" {...register("cooperationName")} />
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        Mailing Address <span className="text-danger">*</span>
                    </label>
                    <textarea
                        rows="4"
                        className="form-control"
                        {...register("mailingAddress")}
                    />
                    <p className="text-danger">{errors.mailingAddress?.message}</p>
                </div>
                <div className="form-group">
                    <div className="mb-3">
                        <h6 className="text-secondary">
                            Search address or Adjust drop pin to change Zip code
                        </h6>
                        <GoogleMapComponent onPostalCodeChange={changeAddress} />
                    </div>

                    <label className="text-secondary">
                        Zip/Postal Code <span className="text-danger">*</span>
                    </label>
                    <input
                        className="form-control"
                        value={zipCode} // Controlled by state
                        onChange={(e) => {
                            setZipCode(e.target.value);
                            setValue("zipCode", e.target.value); // Update react-hook-form value
                        }}
                    />
                    <p className="text-danger">{errors.zipCode?.message}</p>
                </div>
                <div
                    className="pb-2 mb-4 mt-4"
                    style={{ borderBottom: "2px solid rgb(140 144 147)" }}
                >
                    <h6 className="text-secondary">Contact Information</h6>
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        Year of Birth <span className="text-danger">*</span>
                    </label>
                    {/* <input
                        className="form-control"
                        type="date"
                        {...register("yearOfBirth")}
                        max={getMaxDate()} // Restrict the calendar to allow only valid dates
                    /> */}
                    <select className="form-control" {...register("yearOfBirth")}>
                        <option value="">Select</option>
                        {yearsArray.map((year) => (
                            <option value={year}>{year}</option>
                        ))}
                    </select>
                    <p className="text-danger">{errors.yearOfBirth?.message}</p>
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        Cell Phone Number <span className="text-danger">*</span>
                    </label>
                    {/* <input className="form-control" {...register("cellPhoneNumber")} /> */}
                    <Controller
                        control={control}
                        name="cellPhoneNumber"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <PhoneInput
                                country={"ca"} onChange={onChange} // send value to hook form
                                onBlur={onBlur} // notify when input is touched/blur
                                value={value} // value for input
                                ref={ref} // necessary for react-hook-form 
                            />
                        )}
                    />

                    <p className="text-danger">{errors.cellPhoneNumber?.message}</p>
                </div>
                <div className="form-group">
                    <label className="text-secondary">Office/Home Phone Number</label>
                    <input
                        className="form-control"
                        {...register("officeHomePhoneNumber")}
                    />
                    <p className="text-danger">{errors.officeHomePhoneNumber?.message}</p>
                </div>
                <div
                    className="pb-2 mb-4 mt-4"
                    style={{ borderBottom: "2px solid rgb(140 144 147)" }}
                >
                    <h6 className="text-secondary">Marine Affiliation</h6>
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        Are you involved in the marine industry?{" "}
                        <span className="text-danger">*</span>
                    </label>
                    <select className="form-control" {...register("marineIndustry")}>
                        <option value="">Select</option>
                        <option value="Buying">Buying</option>
                        <option value="Selling">Selling</option>
                        <option value="Browsing">Browsing</option>
                    </select>
                    <p className="text-danger">{errors.marineIndustry?.message}</p>
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        Would you like to receive a call for personalized assistence?{" "}
                        <span className="text-danger">*</span>
                    </label>
                    <select className="form-control" {...register("receiveCall")}>
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="soon / Yes">soon / Yes</option>
                        <option value="later / No">later / No</option>
                        <option value="not at this time">not at this time</option>
                    </select>
                    <p className="text-danger">{errors.receiveCall?.message}</p>
                </div>
                <div
                    className="pb-2 mb-4 mt-4"
                    style={{ borderBottom: "2px solid rgb(140 144 147)" }}
                >
                    <h6 className="text-secondary">Social Media Insights</h6>
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        Primary Social Media Platform <span className="text-danger">*</span>
                    </label>
                    <select className="form-control" {...register("primarySocialMedia")}>
                        <option value="">Select</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Instagram">Instagram</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Twitter">Twitter</option>
                        <option value="Other">Other</option>
                    </select>
                    <p className="text-danger">{errors.primarySocialMedia?.message}</p>
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        Secondary Social Media Platform{" "}
                        <span className="text-danger">*</span>
                    </label>
                    <select
                        className="form-control"
                        {...register("secondarySocialMedia")}
                    >
                        <option value="">Select</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Instagram">Instagram</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Twitter">Twitter</option>
                        <option value="Other">Other</option>
                    </select>
                    <p className="text-danger">{errors.secondarySocialMedia?.message}</p>
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        Primary Search Engine <span className="text-danger">*</span>
                    </label>
                    <select className="form-control" {...register("primarySearchEngine")}>
                        <option value="">Select</option>
                        <option value="Google">Google</option>
                        <option value="Bing">Bing</option>
                        <option value="Yahoo">Yahoo</option>
                        <option value="Other">Other</option>
                    </select>
                    <p className="text-danger">{errors.primarySearchEngine?.message}</p>
                </div>
                <div className="form-group">
                    <label className="text-secondary">
                        Secondary Search Engine <span className="text-danger">*</span>
                    </label>
                    <select
                        className="form-control"
                        {...register("secondarySearchEngine")}
                    >
                        <option value="">Select</option>
                        <option value="Google">Google</option>
                        <option value="Bing">Bing</option>
                        <option value="Yahoo">Yahoo</option>
                        <option value="Other">Other</option>
                    </select>
                    <p className="text-danger">{errors.secondarySearchEngine?.message}</p>
                </div>
                <Button variant="secondary" type="submit" disabled={submitting}>
                    {submitting ? (
                        <>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Loading...
                        </>
                    ) : (
                        "Submit"
                    )}
                </Button>
            </form>
        </div>
    );
};

export default SingupForm;
