import { Url } from "../api_settings/api_constants";
import { wrappedFetch, wrappedGet } from "../api_settings/urlFunctions";


// send sms api 
export const sendSms = (data) => {
    return wrappedFetch(Url.SEND_SMS, "POST", data);
};

// verify user===
export const verifyUser = (data) => {
    return wrappedFetch(Url.VERIFY_USER__URL, "POST", data);
};


// chat profile===
export const chatProfile = (data) => {
    return wrappedFetch(Url.CHAT_PROFILE, "POST", data);
};


//profile complete
export const profileComplete = (data) => {
    return wrappedFetch(Url.PROFILE_COMPLETE, "POST", data);
};

// login user ---
export const userLogin = () => {
    return wrappedGet(Url.LOGIN_URL, "GET");
};

// update profile
export const updateProfile = (data) => {
    return wrappedFetch(Url.UPDATE_PROFILE_URL, "POST", data);
};

// signup user
export const signupUser = (data) => {
    return wrappedFetch(Url.SIGNUP_USER, "POST", data);
}

// update profile
export const getAccessToken = () => {
    return localStorage.getItem('token')
};

