import React, { useEffect, useRef, useState } from "react";
import "./GoogleMapComponent.css"

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY; // Replace with your API Key
const defaultLocation = { lat: 45.424721, lng: -75.695000 }; // Default location (Ottawa, CA)

const GoogleMapComponent = ({ onPostalCodeChange }) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const autocompleteRef = useRef(null);
    const [postalCode, setPostalCode] = useState(null);
    const [address, setAddress] = useState(null);
    const [locationData, setLocationData] = useState(null);

    useEffect(() => {
        // Load the Google Maps API
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;
        script.onload = initializeMap;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const initializeMap = () => {
        // Initialize the map
        const map = new window.google.maps.Map(mapRef.current, {
            center: defaultLocation,
            zoom: 14,
        });

        // Create a draggable marker
        const marker = new window.google.maps.Marker({
            position: defaultLocation,
            map,
            draggable: true,
        });
        markerRef.current = marker;

        // Initialize Autocomplete
        const input = document.getElementById("autocomplete");
        const autocomplete = new window.google.maps.places.Autocomplete(input);
        autocomplete.bindTo("bounds", map);
        autocompleteRef.current = autocomplete;

        // Add place_changed listener
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const newLocation = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
                map.setCenter(newLocation);
                marker.setPosition(newLocation);
                fetchAddressDetails(newLocation);
            }
        });

        // Get current user location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    map.setCenter(userLocation);
                    marker.setPosition(userLocation);
                    fetchAddressDetails(userLocation);
                },
                () => {
                    console.log("Using default location");
                    fetchAddressDetails(defaultLocation);
                }
            );
        } else {
            console.log("Geolocation not supported");
            fetchAddressDetails(defaultLocation);
        }

        // Add dragend listener to the marker
        marker.addListener("dragend", () => {
            const position = marker.getPosition();
            const newLocation = { lat: position.lat(), lng: position.lng() };
            fetchAddressDetails(newLocation);
        });
    };

    const fetchAddressDetails = (location) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location }, (results, status) => {
            if (status === "OK" && results[0]) {
                const addressComponents = results[0].address_components;

                // Extract the full address
                const fullAddress = results[0].formatted_address;

                // Extract latitude and longitude
                const latitude = location.lat;
                const longitude = location.lng;

                // Extract country and state
                const countryComponent = addressComponents.find((component) =>
                    component.types.includes("country")
                );
                const countryId = countryComponent?.short_name || "Country not found";

                const stateComponent = addressComponents.find((component) =>
                    component.types.includes("administrative_area_level_1")
                );
                const stateId = stateComponent?.short_name || "State not found";

                // Extract postal code
                const postalCodeComponent = addressComponents.find((component) =>
                    component.types.includes("postal_code")
                );
                const postalCode = postalCodeComponent?.long_name || "Postal code not found";

                // Update the state with all the details
                setAddress(fullAddress);
                setPostalCode(postalCode);
                // setLocationData({
                //     address: fullAddress,
                //     postal_code: postalCode,
                //     lat_lng: latitude + "_" + longitude,
                //     state_id: stateId,
                //     country_id: countryId
                // });
                onPostalCodeChange({
                    address: fullAddress,
                    postal_code: postalCode,
                    lat_lng: latitude + "_" + longitude,
                    state_id: stateId,
                    country_id: countryId
                })
                console.log("Full Address:", fullAddress);
                console.log("Postal Code:", postalCode);
                console.log("Country ID:", countryId);
                console.log("State ID:", stateId);
                console.log("Latitude:", latitude);
                console.log("Longitude:", longitude);
            } else {
                console.error("Geocode was not successful:", status);
                onPostalCodeChange(null);
            }
        });
    };


    const fetchPostalCode = (location) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location }, (results, status) => {
            if (status === "OK" && results[0]) {
                const postalCodeComponent = results[0].address_components.find((component) =>
                    component.types.includes("postal_code")
                );
                const postalCode = postalCodeComponent?.long_name || "Postal code not found";
                setPostalCode(postalCode);
                console.log("Postal Code:", postalCode);
            } else {
                console.error("Geocode was not successful:", status);
            }
        });
    };

    return (
        <div>
            <input
                id="autocomplete"
                type="text"
                placeholder="Search for a location"
                style={{
                    width: "100%",
                    height: "30px",
                    marginBottom: "10px",
                    padding: "10px",
                }}
            />
            <div ref={mapRef} style={{ width: "100%", height: "500px" }}></div>
            <div style={{ marginTop: "20px" }}>
                <strong>Postal Code:</strong> {postalCode || "Fetching..."}<br />
                <strong>Address:</strong> {address || "Fetching..."}
            </div>
        </div>
    );
};

export default GoogleMapComponent;
